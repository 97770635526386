<template>
  <!-- TODO: Convert px values to rem -->
  <Card class="w-1/4 sticky mr-8 self-start" style="top: 160px">
    <div class="flex flex-col pt-2">
      <p class="px-4 mb-2 font-bold">
        {{ courseAreaName }}
      </p>

      <div
        v-scroll-spy-link
        v-scroll-spy-active="{ class: 'active' }"
        class="flex flex-col overflow-y-auto"
        :style="getNavStyle"
      >
        <a
          v-for="item in navItems"
          :key="item.id"
          class="block pl-6 pr-4 py-2 hover:underline"
          :class="`scroll-${colour}`"
          :href="item.id"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface NavItem {
  id: string
  name: string
}

interface SideNavProps {
  courseAreaName: string
  navItems: NavItem[]
  colour?: string
}

const viewport = useViewport()
const { vScrollSpyLink, vScrollSpyActive } = useScrollSpy()

const getNavStyle = computed(() => {
  return viewport.isGreaterOrEquals('lg')
    ? 'max-height: calc(100vh - 280px)'
    : ''
})

const props = withDefaults(defineProps<SideNavProps>(), {
  courseAreaName: '',
  navItems: () => [],
  colour: ''
})
</script>

<style lang="postcss" scoped>
div a.scroll-ochre.active {
  @apply bg-brand-ochre-300;
}

div a.scroll-grey.active {
  @apply bg-brand-grey-300;
}

div a.scroll-blue.active {
  @apply bg-brand-blue-300;
}

div a.scroll-teal.active {
  @apply bg-brand-teal-300;
}

div a.scroll-azure.active {
  @apply bg-brand-azure-300;
}

div a.scroll-lavender.active {
  @apply bg-brand-lavender-300;
}

div a.scroll-peach.active {
  @apply bg-brand-peach-300;
}

div a.scroll-yellow.active {
  @apply bg-brand-yellow-300;
}

div a.scroll-green.active {
  @apply bg-brand-green-300;
}

div a.scroll-aqua.active {
  @apply bg-brand-aqua-300;
}

div a.scroll-mauve.active {
  @apply bg-brand-mauve-300;
}

div a.scroll-pink.active {
  @apply bg-brand-pink-300;
}

div a.scroll-apricot.active {
  @apply bg-brand-apricot-300;
}
</style>
